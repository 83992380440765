

.condition-bc
{
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.233);
    // min-height: 100%;
    min-width: 100%;
    z-index: 4000;
    overflow: hidden;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: center;
}

.condition-container
{
    z-index: 40001;
    padding: 40px;
    text-align: left;
    background-color: white;
    max-width: 440px;
    min-height: 230px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 1px transparent;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.562);
}

.condition-container > *
{
    padding-top: 2px;
    padding-bottom: 2px;
}

.condition-input
{
    width: 100%;
    min-height: 35px;
    padding: 5px;
    font-size: 18px;
}

.container-buttons
{
    text-align: center;
}

.container-button
{
    cursor: pointer;
    color: white;
    font-size: 22px;
    border: 1px transparent;
    border-radius: 5px;
    width: 120px;
    height: 48px;
    margin: 5px;
}

.__container-button-blue
{
    background-color: rgb(51, 116, 255);
}

.__container-button-red
{
    background-color: rgb(255, 51, 51);
}

.container-button:hover
{
    opacity: .9;
}

