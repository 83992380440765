$newpost-max-width: 600px;

button, input, textarea
{
    outline: none;
}

.newpost-container
{
    text-align: center;
    margin: auto;
    margin-bottom: 80px;
    // box-shadow: 0px 0px 5px;
    margin: auto;
    border: 1px transparent;
    // border-radius: 10px;
    max-width: $newpost-max-width;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.newpost
{
    // text-align: left;
    margin: auto;
    padding: 10px;
    // border: 1px solid;
    // max-width: $newpost-max-width;
}

.newpost-titles
{
    font-weight: bold;
    font-size: 25px;
}

section
{
    padding: 5px;
}

section > Div > input
{
    height: 30px;
    // width: 100%;
    padding-left: 5px;
    font-size: 20px;
}

.newpost-input-container > textarea
{
    resize: none;
    // height: 120px;
    // width: 100%;
    padding: 5px;
    font-size: 14px;
}

.newpost-drozone
{
    cursor: pointer;
    position: relative;
    height: 120px;
    // width: 100%;
    padding: 5px;
    border: 1px dashed rgb(149, 163, 25);
    border-radius: 8px;
    text-align: center;
    background-color: rgba(234, 248, 104, 0.788);
}

.newpost-drozone::before, .newpost-drozone::after
{
    cursor: pointer;
}

.newpost-drozone:hover
{
    background-color: rgba(226, 240, 106, 0.884);
}

.newpost-drozone > label
{
    cursor: pointer;
    // width: 100%;
    font-size: 45px;
    color: rgb(148, 145, 0);
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.newpost-error-label
{
    color: red;
}

.newpost-description
{
    color: rgb(163, 163, 163);
}

.newpost-properties
{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.newpost-properties > div
{
    padding-top: 5px;
}

.newpost-properties > div > label
{
    color: rgb(141, 141, 141);
}

input::placeholder,
textarea::placeholder
{
    font-style: italic;
    color: rgb(204, 204, 204);
}

.newPostSubmitCls
{
    text-align: center; 
}

.newPostSubmitCls > button
{
    cursor: pointer;
    height: 60px;
    width: 120px;

    background-color: rgb(222, 241, 42);
    border: 1px solid rgb(222, 241, 42);
    border-radius: 10px;
}

.newPostSubmitCls > button:hover
{
    background-color: rgb(194, 211, 45);
    border: 1px solid rgb(194, 211, 45);
}

.newpost-toggle-type
{
    cursor: pointer;
    background-color: rgb(142, 172, 216);
    border: 1px solid rgb(124, 116, 235);
    height: 35px;
    width: 60px;
    border-radius: 5px;
    color: rgb(19, 0, 189);
}

.newpost-file-list
{
    padding-left: 10px;
    display: flex;
    margin-top: 10px;
    // width: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 5px black;
    min-height: 50px;
    align-items: center;
    justify-content: flex-start;
    
}

.newpost-file-delete
{
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}


.newpost-file-delete-btn:hover
{
    background-color: rgb(221, 221, 221);
    border: 1px solid rgb(221, 221, 221);
    border-radius: 5px;
}

.newpost-hashtag-container
{
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    outline: none;
    padding: 2px;
    border: 1px solid rgb(165, 165, 165);
    height: 40px;
    border-radius: 2px;
    font-size: 30px;
}

