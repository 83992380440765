

.search-detail-container
{
    padding: 18px;
    text-align: left;
    min-height: 100vh;
}

.search-label
{
    display: inline;
    font-weight: bold;
    font-size: 22px;
}

.search-posts-container
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.search-posts
{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 100vh;
}