body
{
    background-color: white;
}

.posts
{
    position: relative;
    display: block;
    text-align: center;
    // border: 1px solid red;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.status-bar
{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.new-post-btn
{
    cursor: pointer;
    background: none;
    border: none;
}

.coins-status > input
{
    height: 25px;
    width: 150px;
}

#coins-label
{
    margin-left: 5px;
}

.coins-status > label
{
    font-size: 20px;
}

.filter-container
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px;
    border-bottom: 1px solid;
}

.filter-container > div
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}