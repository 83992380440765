$list-bc: rgb(236, 236, 236);

.follows-container
{
    display: flex;
}

.follows-list-container
{
    align-items: left;
    text-align: left;
    background-color: rgb(236, 236, 236);
    min-height: 100vh;
    min-width: 290px;
    width: 290px;
}

.follows-post-container
{
    padding: 8px;
    position: relative;
    height: 100vh;
    flex-grow: 1;
    overflow-y: scroll;
}

.follows-list
{
    display: flex;
    flex-direction: column;
}

.follows-list > button
{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    height: 40px;
    background-color: $list-bc;
    border: 1px transparent;
    border-radius: 5px;
}

.follows-list > button > span
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.follows-list > button > span > *
{
    cursor: pointer;
    margin-right: 5px;
}

.follows-list > button:hover
{
    background-color: rgb(206, 206, 206);
}

.follows-list > button > *
{
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

.follow-unfollow
{
    cursor: pointer;
    background-color: rgb(190, 190, 190);
    padding: 5px;
    border: 1px transparent;
    border-radius: 4px;
    transition: background-color .1s ease-in;
}

.follow-unfollow:hover
{
    background-color: tomato;
    color: white;
}

.follow-image
{
    background-color: gray;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.follow-selected-user
{
    background-color: rgb(226, 226, 226);
}