$nav-bc-color: white;
$nav-border-radius: 15px;
$nav-global-padding: 15px;
$selected-color: rgb(25, 131, 231);
$selected-color-hover: rgb(25, 107, 231);

body
{
    margin: 0;
}

.navigationbar-container
{
    background-color: $nav-bc-color;
    position: sticky;
    top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.navigationbar
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // border: 1px solid;
}

.navigationbar > div
{
    // border: 1px solid;
}

.memeHubCls
{
    font-weight: bold;
    font-size: 40px;
}

.nav-menu-container
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    // height: 100px;
}

.nav-menu-container > div
{
    display: flex;
    align-items: center;
}

.nav-menu-left
{
    justify-content: flex-start;
}

.nav-menu-right
{
    justify-content: flex-end;
}

.nav-details-container
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.nav-pages-container
{
    display: flex;
    align-items: center;
}

.nav-menu
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.nav-menu > span
{
    width: 200px;
}

#signInLinkId
{
    text-decoration: none;
}

.nav-searchbar-container
{
    padding: 4px;
    display: flex;
    justify-content: center;
    height: 35px;
    width: 350px;
}

.nav-searchbar
{
    display: flex;
    justify-content: flex-start;
    padding: 5px;
    margin-left: 10px;
    width: 450px;
    padding-left: 10px;
    // background-color:rgb(255, 255, 255);
    // border: 1px solid rgb(201, 201, 201);
}

.nav-logo-container
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-search-btn
{
    margin-left: 5px;
    cursor: pointer;
    background-color: rgb(232, 232, 232);
    border: 1px solid rgb(212, 212, 212);
    border-radius: 8px;
    border-left: none;
    width: 70px;

}

.nav-search-btn:hover
{
    background-color: rgb(218, 218, 218);
}

.nav-search-input
{
    width: 100%;
    font-size: 18px;
    border: none;
}

.nav-property-container
{
    display: inline-block;
    background-color: rgb(228, 228, 228);
    padding: 5px;
    margin: 5px;
    border: 1px transparent;
    border-radius: 8px;
}

.nav-property-container-interactable
{
    cursor: pointer;
}

.nav-property-container-interactable:hover
{
    background-color: rgb(224, 224, 224);
}

.page-btn
{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(238, 238, 238);
    padding: 6px;
    margin: 3px;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 15px;
}

.page-btn:hover
{
    background-color: rgb(234, 234, 234);
}

.page-btn > *
{
    margin: 3px;
}

.page-btn > span
{
    font-weight: bold;
    font-size: 14px;
    font-style: none;
}

.newpost-add-container
{
    position: sticky;
    top: 143px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
}

.newpost-add
{
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin: 8px;
    border: 1px transparent;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(1, 1, 1, 0.5);
}


.status-container
{
    position: sticky;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    border-top: 1px solid;
    background-color: white;
    height: 40px;
    z-index: 1000;
}

.status
{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    overflow: hidden;
    height: 100%;
}

.status > button
{
    cursor: pointer;
    background-color: transparent;
    border: none;
    overflow: hidden;
}

.bottom
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3px;
    background-color: rgb(241, 241, 241);
    min-height: 40px;
    border-top: 1px solid gray;

}

.bottom-content
{

    color: gray;
}

.bottom-content > *
{
    margin-left: 5px;
}

.bottom-copyright
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bottom-contact
{
    text-align: center;
}

.newpost-hashtag
{
    display: inline-block;
    color: rgb(0, 0, 255);
    padding: 5px;
    margin: 2px;
    background-color: rgba(57, 134, 221, 0.459);
    border: 1px transparent;
    border-radius: 5px;
}

.btn
{
    cursor: pointer;
    background-color: rgb(232, 255, 25);
    border: 1px transparent;
    border-radius: 5px;
    transition: all .060s ease-in;
}

.btn:hover
{
    background-color: rgb(223, 245, 29);
}

.btn-blue
{
    cursor: pointer;
    background-color: rgb(25, 117, 255);
    border: 1px transparent;
    border-radius: 5px;
    transition: all .060s ease-in;
    color: white;

    &:hover
    {
        background-color: rgb(25, 86, 255);
    }
}

.btn-deactivated
{
    background-color: rgb(190, 190, 190);
    border: 1px transparent;
    border-radius: 5px;
    transition: all .060s ease-in;
    color: white;
}

.del-btn
{
    cursor: pointer;
    background: none;
    padding: 8px 10px 8px 10px;
    border: 1px transparent;
    border-radius: 5px;
    transition: all .1s ease-in;
    color: red;
    font-weight: bold;
}

.del-btn:hover
{
    background-color: rgb(175, 175, 175);
}

.field
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(232, 232, 232);
    border: 1px solid rgb(212, 212, 212);
    // border: 1px solid transparent;
    border-radius: 8px;
    padding: 2px;
    // width: 300px;
    
}

.input-field
{
    background: none;
    border: 1px transparent;
    // border: 1px solid;
    width: 100%;
}

.textarea-field
{
    background: none;
    border: 1px transparent;
    // border: 1px solid;
    width: 100%;
    height: 110px;
    flex-grow: 1;
    resize: none;
}

.dropdown
{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: aliceblue;
    background-color: rgb(232, 232, 232);
    border: 1px solid rgb(212, 212, 212);
    // border: 1px solid transparent;
    border-radius: 8px;
    padding: 10px;
    // width: 300px;
    transition: background-color .1s ease-in;
}

.dropdown:hover
{
    background-color: rgb(224, 224, 224);
}

.del
{
    cursor: pointer;
    margin-left: 5px; // temp
    background-color: rgb(177, 177, 177);
    border: 1px transparent;
    border-radius: 8px;
    color: red;
    transition: ease-in .2s background-color;
    font-size: 16px;
}

.del:hover
{
    background-color: red;
    color: white;
}

.list-container
{
    align-items: left;
    text-align: left;
    background-color: rgb(236, 236, 236);
    min-height: 100vh;
    min-width: 290px;
    width: 290px;
}

.list-list
{
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.list-item
{
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(221, 221, 221);
    border: 1px transparent;
    border-radius: 10px;
    margin-top: 8px;
    min-height: 40px;
}

.list-item-left, .list-item-right
{
    display: flex;
    flex-wrap: wrap;
}

.list-item-interactable
{
    cursor: pointer;
    transition: all .1s ease-in;
}

.list-item-interactable:hover
{
    background-color: rgb(212, 212, 212);
}

.list-item-selected
{
    cursor: pointer;
    background-color: rgb(25, 131, 231);
    transition: all .1s ease-in;
    color: white;

    &:hover
    {
        background-color: rgb(25, 107, 231);
    }
}


.panel
{
    padding: 10px;
    background-color: rgb(248, 248, 248);
    border: 1px transparent;
    border-radius: 8px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
    margin: 10px;
}

.panel2
{
    text-align: left;
    padding: 10px;
    background-color: rgb(224, 224, 224);
    border: 1px transparent;
    border-radius: 8px;
    margin: 10px;
}

.content-page
{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: "10px";
}

.content-page2
{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: "10px";
}

.back-btn
{
    cursor: pointer;
    background-color: $selected-color;
    color: white;
    font-weight: bold;
    padding: 12px 30px 12px 30px;
    border: 1px transparent;
    border-radius: 20px;
    font-size: 15px;
    transition: background-color .1s ease-in;

    &:hover
    {
        background-color: $selected-color-hover;
    }
}

.option-selector
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.option-selector > button
{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: none;
    padding: 4px;
    margin: 3px;
    border: 1px transparent;
    border-radius: 10px;
    transition: background-color .2s ease-in-out;
}

.option-selector-selected
{
    background-color: rgb(218, 218, 218);
}

.error-text
{
    display: inline-block;
    color: red;
    padding: 5px;
}