.banpage-container
{
    min-height: 100vh;
}

.banpage-date-container
{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.banpage-date
{
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.banpage-date > span > span
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    margin-left: 15px;
}

.banpage-date > span > span > div > input
{
    text-align: center;
    font-size: 25px;
    // 30px
    width: 45px;
}

.banpage-btn
{
    padding: 20px 40px 20px 40px;
    color: white;
    background-color: rgb(228, 47, 47);
    transition: background-color .2s ease-in-out;
    font-weight: bold;

    &:hover
    {
        background-color: rgb(231, 31, 31);
    }
}

.banpage-btn > span
{
    font-weight: normal;
}