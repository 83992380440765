.badge-container
{
    margin: 10px;
    background-color: rgb(228, 228, 228);
    border: 1px transparent;
    padding: 6px;
    border-radius: 10px;
}

.badge-container > img
{
    margin-left: 3px;
    margin-right: 3px;
}