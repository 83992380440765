$post-radius: 15px;
$post-max-width: 570px;
$post-video-button-size: 20px;

.postCls
{
    align-items: center;
    justify-items: center;
    position: relative;
    // border: 1px transparent;
    // border-radius: $post-radius;
    // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    margin: auto;
    margin-bottom: 10px;
    max-width: 570px;
    min-width: 200px;
    padding: 10px;
    // background-color: rgb(236, 236, 236);
    background-color: transparent;
    // max-height: 800px;
}



.imagePlaceHolderCls
{
    width: 100%;
    // border: 1px solid rgb(126, 126, 126);
    // border-radius: $post-radius;
}



.imagePlaceHolderCls > img
{
    width: 100%;
    border-radius: $post-radius;
    max-width: $post-max-width;
}

.userDivCls
{
    display: flex;
    justify-content: space-between;
    // background-color: black;
    // color: white;
    // border: 1px solid;
    width: 100%;
}

.userDivCls > span
{
    display: flex;
    align-items: center;
    // color: white;
}

.userDivCls > span > button
{
    margin: 4px;
}

.userDivCls > span > label
{
    font-weight: bold;
    color: white;
}

.authorInfoCls
{
    text-align: left;
    width: 100%;
}

.titleCls
{
    font-weight: bold;
}

.descriptionCls
{

}

.optionsBarCls
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    width: 100%;
}

.descriptionCls > label
{
    color: rgb(110, 110, 110);
    font-weight: 580;
}

.likeBtnCls
{
    text-align: center;
}

.likeDislikeCls
{
    display: flex;
    text-align: center;
}

.likeDislikeCls > button > img
{
    width: 30px;
}

.likeDislikeCls > button
{
    cursor: pointer;
    background: none;
    border: none;
}

.likeCountCls
{
    font-size: 25px;
    margin-left: 3px;
}

.optionsBarCls
{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.boostBtnCls
{
    cursor: pointer;
    height: 40px;
    width: 90px;

    background-color: rgb(222, 241, 42);
    border: 1px solid rgb(222, 241, 42);
    border-radius: 5px;
}

.boostBtnCls:hover
{
    background-color: rgb(194, 211, 45);
    border: 1px solid rgb(194, 211, 45);
}

.boostDateCls > label
{
    color: rgb(134, 134, 134);
    margin-right: 5px;
}

.unclickedBoostBtnCls
{
    // cursor: pointer;
    height: 40px;
    width: 90px;

    background-color: rgb(214, 214, 214);
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
}

.image-container
{
    position: relative;
    width: 100%;
    max-width: $post-max-width;
    display: flex;
    margin-inline: auto;
    justify-content: center;
    // border: 2px solid red;
    // max-height: 90vh;
    border-radius: $post-radius;
    background-color: black;
    overflow: hidden;
}

.image-container::before, .image-container::after, .image-container
{
    box-sizing: border-box;
    color: white;
}

.image-container > img
{
    width: 100%;
    // max-height: 100vh;
    display: block;

    // -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
    // border-radius: $post-radius;
}


// all about video container
.videoContainerCls
{
    position: relative;
    width: 100%;
    max-width: $post-max-width;
    display: flex;
    margin-inline: auto;
    justify-content: center;
    max-height: 90vh;
    border-radius: $post-radius;
    background-color: black;
}

.videoContainerCls::before, .videoContainerCls::after, .videoContainerCls
{
    box-sizing: border-box;
    color: white;
}

.videoContainerCls > video
{
    width: 100%;

    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
    border-radius: $post-radius;
}

.videoControlsContainerCls
{
    position: absolute;
    justify-content: flex-start;
    text-align: left;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    z-index: 100;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

.videoContainerCls:hover .videoControlsContainerCls,
.videoContainerCls.paused .videoControlsContainerCls
{
    opacity: 1;
}

.videoControlsContainerCls .videoControlsCls
{
    display: flex;
    gap: .5rem;
    padding: .75rem;
    align-items: center;
}

.videoControlsContainerCls::before
{
    content: "";
    position: absolute;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
    width: 100%;
    aspect-ratio: 6 / 1;
    z-index: -1;
    pointer-events: none;
    border-radius: $post-radius;
}

.videoControlsContainerCls .videoControlsCls > button,
.videoControlsContainerCls .videoControlsCls > div > button
{
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: inherit;
    padding: 0;
    height: $post-video-button-size;
    width: $post-video-button-size;
    font-size: 1.1rem;
    opacity: .80;
    transition: opacity 50ms ease-in-out;
}

.videoControlsContainerCls .videoControlsCls > button:hover,
.videoControlsContainerCls .videoControlsCls > div > button:hover
{
    opacity: 1;
}

.videoControlsContainerCls .videoControlsCls > button > img,
.videoControlsContainerCls .videoControlsCls > div > button > img
{
    height: $post-video-button-size;
    width: $post-video-button-size;
}

.volumeContainerCls
{
    display: flex;
    align-items: center;
}

.videoVolumeSlider
{
    width: 0;
    transform-origin: left;
    transform: scaleX(0);
    transition: width 150ms ease-in-out, transform 150ms ease-in-out;
}

.volumeContainerCls:hover .videoVolumeSlider
{
    width: 100px;
    transform: scaleX(1);
}

.videoDurationContainerCls
{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .25rem;
    flex-grow: 1;
}

.videoTimelineCls
{
    cursor: pointer;
    display: flex;
    height: 7px;
    margin-inline: 2rem;
    align-items: center;
}

.videoTimelineCls:hover .timeLineCls
{
    // height: 100%;
}

.timeLineCls
{
    position: relative;
    height: 3px;
    background-color: rgba(100, 100, 100, 0.5);
    width: 100%;
}

.timeLineCls::before
{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: calc(100% - var(--preview-line, .25) * 100%);
    background-color: rgb(150, 150, 150);
    display: none;
}

.timeLineCls::after
{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: calc(100% - var(--progress-line) * 100%);
    background-color: rgb(222, 241, 42);
}

.timeLineCls .thumbIndicatorCls
{
    --scale: 0;
    position: absolute;
    transform: translateX(-50%) scale(var(--scale));
    height: 200%;
    top: -50%;
    left: calc(var(--progress-line) * 100%);
    background-color: rgb(222, 241, 42);
    border-radius: 50%;
    transition: transform 150ms ease-in-out;
    aspect-ratio: 1 / 1;
}

.videoContainerCls.scrubbing .timeLineCls,
.videoTimelineCls:hover .timeLineCls::before
{
    display: block;
}

.videoContainerCls.scrubbing .thumbIndicatorCls,
.videoTimelineCls:hover .thumbIndicatorCls
{
    --scale: 1;
}

.newpost-follow-btn
{
    cursor: pointer;
    padding: 10px 10px 10px 10px;
    background-color: rgb(228, 47, 47);
    color: white;
    // font-weight: 600;
    border: 2px solid rgb(228, 47, 47);
    border-radius: 5px ;
    // height: 30px;
    margin-left: 4px;
    margin-right: 4px;
    transition: background-color .2s ease-in-out;
}

.newpost-follow-btn:hover
{
    background-color: rgb(231, 31, 31);
    // color: white;
}

.post-verify-btn
{
    cursor: pointer;
    font-weight: bold;
    background-color: rgba(0, 204, 0, 0.336);
    color: green;
    border: 1px transparent;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    transition: all .1s ease-in;

    &:hover
    {
        background-color: rgba(1, 175, 1, 0.452);
    }
}