.message-container
{
    min-height: 100vh;
}

.message-header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message-header > div
{
    display: flex;
    align-items: center;
}

.message-content-render
{
    white-space: pre-line;
}