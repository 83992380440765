html
{
    height: 100%;
}

body
{
    background-color: white;
}

*, *::before, *::after
{
    font-family: Helvetica;
}

span, label
{
    text-overflow: ellipsis;
    overflow: hidden;
}