
.dashboard-container
{
    display: flex;
    height: 100vh;
    
}

.dashboard-content-container
{
    // padding: 15px;
    flex-grow: 1;
    height: 100vh;
    overflow-y: scroll;
}

.dashboard-options-container
{
    // padding: 8px;
    height: 100vh;
    min-width: 290px;
    width: 290px;
    border-right: 1px solid;
}

.small-panel
{
    padding: 8px;
    background-color: rgb(197, 197, 197);
    border: 1px transparent;
    border-radius: 8px;
}

.dashboard-options
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    // border: 1px solid;
}

.dashboard-options-btn
{
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
    height: 40px;
    border: 1px transparent;
    border-radius: 5px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    background-color: rgb(224, 224, 224);
}

.dashboard-options > button:hover
{
    background-color: rgb(207, 207, 207);
}

.dashboard-options-btn-selected
{
    background-color: rgb(212, 212, 212);
}

.dashboard-section-title
{
    font-weight: bold;
    text-align: left;
    font-size: 14px;
}

.dashboard-post-analytics
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: rgb(216, 216, 216);
    border: 1px transparent;
    border-radius: 8px;
    margin: 10px;
}

.dashboard-post-analytics > div
{
    display: flex;  
    align-items: center;
    flex-wrap: wrap;
}

.dashboard-post-analytics-left > span,
.dashboard-post-analytics-right > span
{
    margin-right: 3px;
    margin-left: 3px;
}

.dashboard-submit
{
    opacity: 0;
    padding: 5px;
    text-align: left;
    margin: 10px;
    border: 1px transparent;
    background-color: rgb(235, 235, 235);
    border-radius: 5px;
    transition: opacity .1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dashboard-message-container > *
{
    margin: 5px;
}

.dashboard-message-section
{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dashboard-message-field
{
    width: 100%;
}

.dashboard-message-field > input
{
    font-size: 20px;
}

.dashboard-message-to-field > *
{

    margin: 2px;
    // border: 1px solid;
}

.dashboard-message-footer
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dashboard-message-footer > span
{
    color: gray;
}

.dashboard-afm-container
{
    // padding: ;
}

.dashboard-afm-logo
{
    width: 100px;
    background-color: rgb(235, 235, 235);
    border: 1px transparent;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}