// body
// {
//     background-color: aquamarine;
// }

.containerCls
{
    position: relative;
    padding: 10px;
    display: flex;
    text-align: center;
    // border: 1px solid red;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.signInCls1
{
    display: flex;
    flex-grow: 1;
    text-align: center;
    max-width: 650px;
    // box-shadow: 0px 0px 5px;
    margin: auto;
    border: 1px transparent;
    border-radius: 10px;
}

.signInCls1 > div
{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;   
    margin: 3px;
}

.sign-details
{
    padding: 10px;
    width: 100%;
}

.sign-fields
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 8px;
    padding: 1px;
    height: 35px;
    width: 100%;
}

.sign-input-fields
{
    background: none;
    border: none;
    width: 100%;
}

.sign-captcha
{
    border: 1px solid;
    border-radius: 4px;
    width: 180px;
    height: 70px;
}

.sign-btn
{
    font-size: 18px;
    height: 40px;
    width: 100%;
}

.sign-title-label
{
    font-weight: bold;
    color: gray;
}

.sign-title-error
{
    display: inline-block;
    color: red;
    padding: 5px;
}