
div
{


    text-align: center;
    // border: 1px solid red;
    justify-content: center;

}

section
{
    text-align: left;
}

.docs-welcome-container
{
    position: relative;
    text-align: center;
}

.docs-article-container
{
    display: block;
    position: relative;
    margin: auto;
    max-width: 620px;
    // border: 1px solid red;
}

.docs-section-description
{

}

