.pfp-image-container
{
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: auto;
    background-color: gray;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 3px;
}