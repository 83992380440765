

.account-page-container
{
    min-height: 100vh;
    padding: 18px;
}

.account-head
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.account-head > *
{
    margin-left: 10px;
    margin-right: 10px;
}



.account-posts-label-container
{
    text-align: left;
}

.account-posts-container
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.promote-to-mod
{
    cursor: pointer;
    margin: 10px;
    background-color: rgba(255, 217, 0, 0.397);
    color: rgb(168, 143, 0);
    border: 1px transparent;
    border-radius: 8px;
    padding: 10px;
    transition: all .1s ease-in;

    &:hover
    {
        background-color: rgba(211, 180, 4, 0.397);;
    }
}


.promote-to-admin
{
    cursor: pointer;
    margin: 10px;
    background-color: rgba(43, 255, 0, 0.562);
    color: rgb(24, 129, 0);
    border: 1px transparent;
    border-radius: 8px;
    padding: 10px;
    transition: all .1s ease-in;

    &:hover
    {
        background-color: rgba(28, 170, 0, 0.562);
    }
}

.account-description
{
    background-color: rgb(230, 230, 230);
    border: 1px transparent;
    border-radius: 10px;
    padding: 10px;
    text-align: left;
    white-space: pre-line;
}

