$list-bc: rgb(236, 236, 236);

.category-container
{
    display: flex;
}

.category-list-container
{
    align-items: left;
    text-align: left;
    background-color: rgb(236, 236, 236);
    min-height: 100vh;
    min-width: 290px;
    width: 290px;
}

.category-post-container
{
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    height: 100vh;
    flex-grow: 1;
    overflow-y: scroll;

}

.category-introducer
{
    display: flex;
    justify-content: space-between;
    margin: 18px;
    text-align: left;
    font-size: 24px;
}

.category-list
{
    display: flex;
    flex-direction: column;
}

.category-list > button
{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    height: 40px;
    background-color: $list-bc;
    border: 1px transparent;
    border-radius: 5px;
}

.category-list-hashtags
{
    width: 20px;
    color: gray;
    font-family:'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-right: 5px;
}

.category-list > button:hover
{
    background-color: rgb(206, 206, 206);
}

.category-plus-btn-image
{
    opacity: .5;
}

.category-plus-btn:hover
{
    .category-plus-btn-image
    {
        opacity: .7;
    }
}

