.loadingbar-container
{
    padding: 10px;
}

.loadingbar
{
    background-color: rgb(223, 223, 223);
    border: 1px transparent;
    border-radius: 7px;
    height: 25px;
}

.loadingbar-done
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(197, 218, 13);
    border: 1px transparent;
    border-radius: 7px;
    height: 25px;
    width: 45%;
    transition: width .1s ease-in-out;
}

.loadingbar-bottom-container
{
    padding: 4px;
}

.loadingbar-bottom
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
}